import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay, Link, Text, useDisclosure
} from '@chakra-ui/react';
import NextLink from 'next/link';
import React from 'react';
import { HiMenuAlt2 } from 'react-icons/hi';

const MobileMenu = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            {/* <AiFillStar size={'24px'} color={'orange'} onClick={onOpen} /> */}
            <HiMenuAlt2 size={'42px'} color={'#fff'} onClick={onOpen} />

            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                size="sm"

            >
                <DrawerOverlay />
                <DrawerContent bgColor={'#181818'}>
                    <DrawerCloseButton />
                    <DrawerHeader>Menu</DrawerHeader>

                    <DrawerBody>
                        <NextLink href='/' passHref legacyBehavior>
                            <Link>
                                <Text color={'#fff'} fontSize={'lg'}>
                                    Jobs
                                </Text>
                            </Link>
                        </NextLink>
                        <NextLink href='https://gameindustryjobs.io/sign-up' passHref legacyBehavior>
                            <Link isExternal>
                                <Text color={'#fff'} fontSize={'lg'} mt={4}>
                                    Create a Profile
                                </Text>
                            </Link>
                        </NextLink>
                        <NextLink href='/post-job' passHref legacyBehavior>
                            <Link>
                                <Text color={'#fff'} fontSize={'lg'} mt={4}>
                                    Post a Job
                                </Text>
                            </Link>
                        </NextLink>
                        <NextLink href='/contact' passHref legacyBehavior>
                            <Link>
                                <Text color={'white'} fontSize={'lg'} mt={4}>
                                    Contact us
                                </Text>
                            </Link>
                        </NextLink>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default MobileMenu;

