import { Container, Heading, Link, SimpleGrid, Text } from '@chakra-ui/react';
import NextLink from "next/link";

function PopularQueries() {
    return (
        <Container maxW={'full'} px={0}>
            <Container maxW={'7xl'} py={12}>
                <Heading as={'h4'} fontSize={'3xl'}>Popular positions</Heading>
                <SimpleGrid minChildWidth='320px' spacing='20px' mt={8} fontSize={'lg'}>
                    <NextLink href={'/search/engineer'} passHref legacyBehavior>
                        <Link>
                            <Text>engineer</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/senior-artist'} passHref legacyBehavior>
                        <Link>
                            <Text>senior artist</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/senior-engineer'} passHref legacyBehavior>
                        <Link>
                            <Text>senior engineer</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/gameplay-animator'} passHref legacyBehavior>
                        <Link>

                            <Text>gameplay animator</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/physics-programmer'} passHref legacyBehavior>
                        <Link>
                            <Text>physics programmer</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/systems-designer'} passHref legacyBehavior>
                        <Link>
                            <Text>systems designer</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/character-artist'} passHref legacyBehavior>
                        <Link>
                            <Text>character artist</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/senior-technical-animator'} passHref legacyBehavior>
                        <Link>
                            <Text>senior technical animator</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/network-programmer'} passHref legacyBehavior>
                        <Link>
                            <Text>network programmer</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/lead-concept-artist'} passHref legacyBehavior>
                        <Link>
                            <Text>lead concept artist</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/backend-engineer'} passHref legacyBehavior>
                        <Link>
                            <Text>backend engineer</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/technical-producer'} passHref legacyBehavior>
                        <Link>
                            <Text>technical producer</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/senior-product-manager'} passHref legacyBehavior>
                        <Link>
                            <Text>senior product manager</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/platform-engineer'} passHref legacyBehavior>
                        <Link>
                            <Text>platform engineer</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/senior-animation-programmer'} passHref legacyBehavior>
                        <Link>
                            <Text>senior animation programmer</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/ux-designer'} passHref legacyBehavior>
                        <Link>
                            <Text>ux designer</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/community-manager'} passHref legacyBehavior>
                        <Link>
                            <Text>community manager</Text>
                        </Link>
                    </NextLink>
                    <NextLink href={'/search/site-realiability-engineer'} passHref legacyBehavior>
                        <Link>
                            <Text>site realiability engineer</Text>
                        </Link>
                    </NextLink>
                </SimpleGrid>
            </Container>
        </Container>
    )
}

export default PopularQueries;
