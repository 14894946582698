import { Box, Container, Flex, IconButton, Link } from '@chakra-ui/react';
import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { FiLinkedin, FiTwitter } from 'react-icons/fi';
import unslugify from '../helpers/unslugify';

const Logo = '/gamedevjobs-logo-1.svg';

function Footer() {
    const router = useRouter();
    const [keyword, setKeyword] = useState('');

    const handleSearchChange = (e) => {
        setKeyword(unslugify(e.target.value).trim());
    };

    const handleKeyDown = (e) => {
        if (e.target.value.length && e.key === 'Enter') {
            router.push('/?q=' + unslugify(e.target.value).trim());
        } else if (e.key === 'Escape') {
            setKeyword('');
        }
    }

    return (
        <Box as="footer" py={6} bgColor={'#070707'} borderTop={'1px solid #191919'} px={[4, 4, 4, 4, 0]}>
            <Container maxW={'7xl'} display={'flex'} justifyContent="space-between" alignItems="center" py={8}>
                <Image src={Logo} width='76' height='63' color='#000' alt='gamedevjobs logo' />
                <Flex alignItems="center" gap={2}>
                    <Link href="/page/1" color={'white'}>Game job index</Link>

                </Flex>
                <Flex gap={4}>
                    <NextLink href='https://twitter.com/gamedevjobs_io' passHref legacyBehavior>
                        <Link isExternal>
                            <IconButton
                                variant='outline'
                                color={'white'}
                                colorScheme='white'
                                aria-label='Menu'
                                fontSize='20px'
                                icon={<FiTwitter />}
                                borderRadius='full'
                                size={'md'}
                                display={{ base: "inline-flex", }}
                            />
                        </Link>
                    </NextLink>
                    <NextLink href='https://www.linkedin.com/company/gamedevjobs/' passHref legacyBehavior>
                        <Link isExternal>
                            <IconButton
                                variant='outline'
                                color={'white'}
                                colorScheme='white'
                                aria-label='Menu'
                                fontSize='20px'
                                icon={<FiLinkedin />}
                                borderRadius='full'
                                size={'md'}
                                display={{ base: "inline-flex", }}
                            />
                        </Link>
                    </NextLink>
                </Flex>

            </Container>
            <Container py={4}>
                <Flex alignItems="center" justifyContent="center" flexDirection={["column", "row",]} gap={4}>
                    <Link href="https://gameindustryjobs.io/privacy-policy" color={'white'}>Privacy Policy</Link>
                    <Link href="/contact" color={'white'}>Contact</Link>
                </Flex>
            </Container>
        </Box>
    )
}

export default Footer;
