import Head from 'next/head';
import * as React from 'react';

function MetaSeo(props) {
    const { children } = props

    return (
        <Head>
            {props.description ? (
                <meta
                    name="description"
                    property="og:description"
                    content={props.description}
                    key="description"
                />
            ) : null}

            {props.keywords ? (
                <meta name="keywords" content={props.keywords} key="keywords" />
            ) : null}

            {children}
        </Head>
    );
}

export default MetaSeo;
