import { Container, Heading, Link, SimpleGrid } from "@chakra-ui/react";
import NextLink from "next/link";
import slugify from "../helpers/slugify";

let categories = [
    'Animation',
    'Art',
    'Audio',
    'Business',
    'Design',
    'DevOps',
    'Engineering',
    'Finance',
    'Human Resources',
    'Legal',
    'Marketing',
    'Operations',
    'Production',
    'Quality Assurance',
    'Web3',
]


function CategoriesList() {
    return (
        <Container maxW={'7xl'} py={12}>
            <Heading as={'h5'} fontSize={'3xl'}>Browse by categories</Heading>
            <SimpleGrid columns={[2, 3, 6]} spacing={8} pt={8}>
                {categories.map((category, index) => (
                    <NextLink href={'/category/' + slugify(category)} passHref key={index} legacyBehavior>
                        <Link fontSize={'xl'}>{category}</Link>
                    </NextLink>
                ))}
            </SimpleGrid>
        </Container>
    )
}

export default CategoriesList;
